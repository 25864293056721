/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "firmwarebuildsapi",
            "endpoint": "https://4upnqo8v3f.execute-api.eu-west-1.amazonaws.com/masterci",
            "region": "eu-west-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://lotddoaupbeeljy2g6yboslj6a.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-rlxyafzmi5fthoznxjg3daywni",
    "aws_cognito_identity_pool_id": "eu-west-1:5f61c051-b728-4468-bcba-431529436e76",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_GOnKa35T2",
    "aws_user_pools_web_client_id": "6ma5k8crrq5fprhgmce6v6b79t",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "firmwarefactory-userdata85748-masterci",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
